<template>
  <div>
    <b-table-simple class="p-table" responsive>
      <thead class="table-header">
        <tr>
          <td class="td-header">Entrada</td>
          <td class="td-header">Fornecedor</td>
          <td class="td-header">Total produ.</td>
          <td class="td-header">Imposto</td>
          <td class="td-header">Total desp.</td>
          <td class="td-header">Valor total</td>
          <td class="td-header"></td>
        </tr>
      </thead>
      <tbody v-for="(entry, index) in entries" :key="index" class="td-body">
      <tr>
        <td class="td-body td-simple">
          {{ new Date(entry.date).toLocaleDateString('pt-BR', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
          }}
        </td>
        <td style="width: 30%" class="td-body td-simple">
          {{ entry.provider ? entry.provider.name : '-' }}
        </td>
        <td class="td-body td-simple">
          {{ getProductTotalValue(entry) }}
        </td>
        <td class="td-body td-simple">{{ parseNumberToMoney(getTaxesValue(entry)) }}</td>
        <td class="td-body td-simple">{{ parseNumberToMoney(getOtherCosts(entry)) }}</td>
        <td class="td-body td-simple">{{ getInvoiceTotalValue(entry) }}</td>
        <td class="td-body">
          <div class="actions">
            <span :id="`file_tooltip_${index}`">
              <b-button class="p-0" variant="link" @click="openEntryFile(entry)">
                <Clip class="file-icon" />
              </b-button>
            </span>
            <b-tooltip v-if="entry.files?.length" :target="`file_tooltip_${index}`" triggers="hover">
              Arquivos anexados
            </b-tooltip>
            <b-tooltip v-else :target="`file_tooltip_${index}`" triggers="hover">
              Anexar arquivos
            </b-tooltip>
            <span :id="`bill_tooltip_${index}`" v-can="'FiConPag1'">
              <b-button class="p-0" variant="link" @click="openBill(entry)" v-if="hasBill(entry)">
                <Bill class="bill-icon" />
              </b-button>
              <b-button class="p-0" variant="link" v-else>
                <DisabledBill class="bill-icon" />
              </b-button>
            </span>
            <b-tooltip v-if="hasBill(entry)" :target="`bill_tooltip_${index}`" triggers="hover">
              Conta a pagar
            </b-tooltip>
            <b-tooltip v-else :target="`bill_tooltip_${index}`" triggers="hover">
              Entrada sem conta a pagar
            </b-tooltip>
            <span :id="`edit_tooltip_${index}`">
              <b-button
                class="p-0"
                variant="link"
                v-can="'EstEnt3'"
                v-if="configuration.entries_outputs_editable"
                @click="onEdit(entry)"
              >
                <Edit
                  class="edit-icon"
                />
              </b-button>
            </span>
            <b-tooltip :target="`edit_tooltip_${index}`" triggers="hover">
              Editar entrada
            </b-tooltip>
            <span>
              <b-button
                class="p-0"
                variant="link"
                v-can="'EstEnt4'"
                :id="`delete_tooltip_${index}`"
                @click="deleteClick(entry)">
                <Delete class="delete-icon" />
              </b-button>
            </span>
            <b-tooltip :target="`delete_tooltip_${index}`" triggers="hover">
              Excluir entrada
            </b-tooltip>
            <b-button
              v-b-toggle="`collapse-${entry.id}-${index}`"
              @click="rotateIcon(entry.id)"
              class="toggle-btn"
            >
              <ChevronDown class="chevron" :class="{ 'rotated': rotatedList.includes(entry.id) }" />
            </b-button>
          </div>
        </td>
      </tr>
      <tr class="collapsable-row">
        <td colspan="7">
          <b-collapse ref="accordion" :id="`collapse-${entry?.id}-${index}`" class="collapse">
            <EntryTransactionsTable
              :transactions="entry.warehouse_product_transactions"
            />
          </b-collapse>
        </td>
      </tr>
      </tbody>
    </b-table-simple>

    <ConfirmEntryModal
      identifier="delete-entry-modal"
      title="Excluir entrada"
      body="Atenção! Tem certeza de que deseja excluir esta entrada?"
      description="Esta ação é irreversível e exclui todas as entradas, parcelas e a conta a pagar."
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      :confirmed="confirmedDelete"
    />

    <EmptyTableResults
      emptyMessage="Cadastre uma entrada para visualizá-la aqui."
      :loading="loading"
      :length="entries ? entries.length : 0"
      :hasFilter="hasFilter"
    />

    <BillsToPayModal
      :billToPayId="billToPayId"
      :showEntryInfo="true"
      @onHide="billToPayId = null"
    />
  </div>
</template>
<script>
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { normalizeProductNumber } from '@/utils/product-helper'
import { mapActions } from 'vuex'

export default {
  props: {
    entries: { type: Array, required: true },
    onEdit: Function,
    onDelete: Function,
    loading: Boolean,
    hasFilter: { validate: v => true }
  },
  mounted() {
    this.getWarehouseConfigurations()
  },
  components: {
    ConfirmEntryModal: () => import('@/components/Warehouse/ConfirmEntryModal.vue'),
    EmptyTableResults: () => import('@/components/General/EmptyTableResults'),
    EntryTransactionsTable: () => import('@/components/Warehouse/EntryTransactionsTable.vue'),
    Clip: () => import('@/assets/icons/paperclip.svg'),
    Bill: () => import('@/assets/icons/bill.svg'),
    DisabledBill: () => import('@/assets/icons/disabled-bill.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    BillsToPayModal: () => import('@/components/Financial/BillsToPayModal')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      configuration: {},
      toDelete: null,
      selectedEntry: null,
      billToPayId: null,
      rotatedList: []
    }
  },
  methods: {
    ...mapActions('warehouse', ['updateSelectedEntry', 'updateSelectedFile']),
    userHasPermission,
    parseNumberToMoney,
    normalizeProductNumber,
    deleteClick(entry) {
      this.toDelete = entry
      this.$bvModal.show('delete-entry-modal')
    },
    hasBill(entry) {
      return !!entry.bill_product?.bill_id && !!entry.bill_product?.bill
    },
    rotateIcon(id) {
      this.rotatedList.includes(id)
        ? this.rotatedList.pop(id)
        : this.rotatedList.push(id)
    },
    confirmedDelete(confirm) {
      if (confirm) {
        this.onDelete(this.toDelete)
      }
      this.toDelete = null
      this.$bvModal.hide('delete-entry-modal')
    },
    getTaxesValue(entry) {
      if (!entry.warehouse_product_transactions) {
        return 0
      }
      return entry.warehouse_product_transactions.reduce((acc, transactions) =>
        acc + ((transactions.icms_value ?? 0) * transactions.quantity), 0
      )
    },
    sumTransactionsTotalValue(entry) {
      if (!entry.warehouse_product_transactions?.length) {
        return 0
      }
      return entry.warehouse_product_transactions.reduce((acc, transaction) =>
        acc + (transaction.purchase_price * transaction.quantity), 0
      )
    },
    getProductTotalValue(entry) {
      if (!entry.total_value) {
        return this.parseNumberToMoney(this.sumTransactionsTotalValue(entry))
      }
      return this.parseNumberToMoney(entry.total_value)
    },
    getInvoiceTotalValue(entry) {
      if (!entry.invoice_total_value) {
        return this.parseNumberToMoney(this.sumTransactionsTotalValue(entry))
      }
      return this.parseNumberToMoney(entry.invoice_total_value)
    },
    getOtherCosts(entry) {
      return entry.delivery_value
        + entry.other_expenses_value
        + entry.insurance_value
        - entry.discount
    },
    openEntryFile(entry) {
      this.updateSelectedEntry(entry)
      this.updateSelectedFile(entry.files[0])
      this.$bvModal.show('warehouse-entry-file-modal')
    },

    openBill(entry) {
      this.updateSelectedEntry(entry)
      this.billToPayId = entry.bill_product?.bill_id
      this.$bvModal.show('bill-to-pay-modal')
    },

    getWarehouseConfigurations() {
      this.api.getWarehouseConfigurations(this.clinic.id)
        .then(res => {
          this.configuration = res.data
        })
        .catch(err => this.$toast.error(err.message))
    }
  }
}
</script>
<style lang="scss" scoped>
.p-table {
  width: 100%;
  text-align: left;
  margin: 20px 0;

  .table-header {
    border-bottom: 1px dashed var(--neutral-300);
    background-color: var(--neutral-100);
  }

  .td-header {
    font-weight: 700;
    font-size: 14px;
    color: var(--type-active);
    border: none;
    padding: 20px 10px;
  }

  .collapsable-row {
    td {
      padding: 0 !important;
      border: none !important;
    }
  }

  .td-body {
    padding: 20px 10px;
    border: 0;
    border-bottom: 1px dashed var(--neutral-300);
    font-weight: 600;
    font-size: 16px;
    color: var(--type-active);
  }

  .td-simple {
    font-weight: normal !important;
  }

  .actions {
    justify-content: right;
    display: flex;

    .file-icon {
      width: 24px;
      stroke: var(--neutral-600) !important;
      cursor: pointer;

      &.disabled {
        stroke: var(--type-placeholder) !important;
        cursor: not-allowed;
      }
    }

    .bill-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      stroke: none;
    }

    .edit-icon {
      width: 24px;
      stroke: var(--neutral-600) !important;
      cursor: pointer;
    }

    .delete-icon {
      width: 24px;
      cursor: pointer;
    }

    .toggle-btn {
      margin-left: 8px;
      background: var(--blue-100);
      border: none;
      padding: 4px 4px !important;
    }

    .chevron {
      width: 24px;
      height: 24px;
      transition: all 0.5s;
      stroke: var(--neutral-600);
      cursor: pointer;

      &.rotated {
        rotate: 180deg;
        transition: rotate 0.5s;
      }
    }
  }

  .number {
    font-family: 'Red Hat Display';
    font-weight: 700;
    color: var(--neutral-500);
  }

  .trashed {
    text-decoration: line-through;
  }

  .more {
    svg {
      stroke: var(--neutral-500);
    }
  }

  .edit-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--blue-500);
  }

  .delete-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--states-error);
  }
}

.more-with-menu {
  width: 24px;
  height: 28px;
  position: relative;
  display: inline-block;

  .more-icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
  }

  .menu {
    position: absolute;
    top: 28px;
    right: 0;
    background: var(--neutral-000);
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
    border-radius: 8px;
    opacity: 0;
    transition: all 0.5s;
    flex-direction: column;
    align-items: start;
    display: none;

    .mtb-12 {
      margin: 12px 0;
    }

    .btn {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;

      &.remove {
        color: var(--states-error);
      }

      &.btn-link {
        padding: 12px 80px 12px 16px !important;
      }
    }
  }

  &:hover {
    .more-icon {
      transform: rotate(90deg);
    }

    .menu {
      opacity: 1;
      display: flex;
      z-index: 10;
    }
  }
}
</style>

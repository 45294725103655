<template>
  <div class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Entrada de produtos
        <span id="help-icon" class="icon-box"><v-help-circle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Efetue a entrada de produtos no seu estoque, tenha controle da quantidade, lote, fornecedores e valores de todas as entradas.
        </b-tooltip>
      </div>

      <div>
        <b-button class="wh-button no-print" variant="outline-primary" outline @click="getEntriesXlsx">
            Download xlsx
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <b-form-group class="form-group">
          <label for="search">Produto</label>
          <b-form-input
            class="placeholder-color"
            id="search"
            debounce="500"
            autocomplete="off"
            placeholder="Buscar produto"
            v-model="query"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group id="field_provider">
          <label for="state">
            Fornecedor
          </label>
          <v-autocomplete
            placeholder="Selecionar"
            debounce="500"
            v-model="provider"
            v-on:typing="(value) => this.providerQuery = value"
            :options="providers.map(p => ({ label: p.name, value: p.id }))"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <v-periods
          label="Período"
          @select="handlePeriod"
        />
      </div>

      <div class="col-2">
        <label for="batch">Lote</label>
        <v-autocomplete
          v-model="batch"
          placeholder="Selecionar"
          v-bind:options="batches"
        />
      </div>
    </div>

    <v-warehouse-entries-table
      :entries="entries"
      :onEdit="onEditClick"
      :onDelete="deleteWarehouseTransaction"
      :loading="loading"
      :hasFilter="hasFilter"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>
    <WarehouseEntryFileModal
      :entry="getSelectedEntry"
      :file="currentFile"
      @close-modal="cleanSelectedEntry"
      @reload-entries="getWarehouseEntries(true)"
    />

  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import { EventBus } from '@/utils/eventBus';
import HelpCircle from '@/assets/icons/help-circle.svg'
import Autocomplete from '@/components/Autocomplete'
import WarehouseEntriesTable from '@/components/Warehouse/WarehouseEntriesTable'
import Periods from '@/components/General/Periods'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'warehouse-entries-tab',
  components: {
    'v-help-circle': HelpCircle,
    'v-warehouse-entries-table': WarehouseEntriesTable,
    'v-autocomplete': Autocomplete,
    'v-periods': Periods,
    WarehouseEntryFileModal: () => import('@/components/Warehouse/WarehouseEntryFileModal'),
  },
  props: {
    onEditClick: Function,
  },
  mounted () {
    this.getWarehouseEntries();
    this.getProviders();
    this.getDistinctBatchNumbers();
    EventBus.$on('reloadProductEntries', () => this.getWarehouseEntries(false));
    EventBus.$on('reloadBillsToPay', () => this.getWarehouseEntries(false));
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));
    return {
      clinicId: clinic.id,
      page: 1,
      limit: 0,
      count: 0,
      query: '',
      provider: null,
      providerQuery: null,
      start: null,
      end: null,
      batch: null,
      batchQuery: null,
      entries: [],
      providers: [],
      batches: [],
      entry: null,
      loading: false,
      hasFilter: false,
    }
  },
  computed: {
    ...mapGetters('warehouse', ['getSelectedEntry', 'getSelectedFile']),
    currentFile() {
      return this.getSelectedFile
    }
  },
  methods: {
    ...mapActions('warehouse', ['clearState']),
    handlePeriod(value) {
      this.start = value.start;
      this.end = value.end;
    },
    getWarehouseEntries(hideLoading) {
      this.loading = true;
      this.hasFilter = (this.query || this.provider || this.start || this.end || this.batch);
      const isLoading = !hideLoading && this.$loading.show();
      const filters = {
        query: this.query,
        provider: this.provider,
        start: this.start,
        end: this.end,
        batch: this.batch,
        page: this.page
      }
      this.api.getWarehouseEntries(this.clinicId, filters)
      .then(res => {
        this.entries = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        this.loading = false;
        !hideLoading && isLoading.hide();
      });
    },
    cleanSelectedEntry() {
      this.clearState();
    },
    getEntriesXlsx() {
      const isLoading = this.$loading.show();
      const filters = {
        query: this.query,
        provider: this.provider,
        start: this.start,
        end: this.end,
        batch: this.batch,
      }
      this.api.getWarehouseEntriesExport(this.clinicId, filters)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'entradas.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    deleteWarehouseTransaction(entry) {
      const isLoading = this.$loading.show();
      this.api.deleteWarehouseEntry(entry.id)
      .then(() => {
        this.$toast.success('Entrada excluída com sucesso!');
        this.getWarehouseEntries();
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    getProviders() {
      this.api.getProviders(this.clinicId, 1, this.providerQuery)
      .then(res => {
        this.providers = res.data.data;
      })
      .catch(err => this.$toast.error(err.message));
    },

    getDistinctBatchNumbers() {
      this.api.getDistinctBatchNumbers(this.clinicId)
      .then(res => {
        this.batches = res.data.map(el => ({ label: el.batch, value: el.batch }) );
      })
      .catch(err => this.$toast.error(err.message));
    },
  },
  watch: {
    query: function() { this.getWarehouseEntries(); },
    provider: function() { this.getWarehouseEntries(); },
    start: function() { this.getWarehouseEntries(); },
    batch: function() { this.getWarehouseEntries(); },
    page: function() { this.getWarehouseEntries(); },
    providerQuery: function() { this.getProviders(); },
  }
}
</script>
<style scoped lang="scss">
  .pt-container {
    padding: 30px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }

    .placeholder-color::placeholder {
      color: var(--type-placeholder);
    }

    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }

    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }

    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }

    .wh-button {
      margin: 0 0 0 10px;
      border-color: var(--blue-500);
      color: var(--blue-500);
    }
  }
</style>
